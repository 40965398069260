var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "选择客户",
            top: "15vh",
            visible: _vm.showDialog,
            width: "1050px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "wrapper",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { "label-width": "80px", inline: true },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: {
                          width: "250px",
                          margin: "0px 10px 0px 0",
                        },
                        attrs: { placeholder: "输入门店,编号" },
                        model: {
                          value: _vm.queryForm.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "keyword", $$v)
                          },
                          expression: "queryForm.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "片区" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          clearable: "",
                          filterable: "",
                          options: _vm.form.area,
                          props: _vm.props,
                        },
                        model: {
                          value: _vm.queryForm.area_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "area_id", $$v)
                          },
                          expression: "queryForm.area_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { margin: "0 0 0 10px" },
                      attrs: { label: "渠道" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.queryForm.channel_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "channel_id", $$v)
                            },
                            expression: "queryForm.channel_id",
                          },
                        },
                        _vm._l(_vm.form.channel, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 10px" },
                          on: { click: _vm.filter },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: { stripe: "", data: _vm.list },
                  on: {
                    "selection-change": _vm.setSelectRows,
                    "cell-click": _vm.cellClick,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "序号",
                      "min-width": "50px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          return [_c("span", [_vm._v(_vm._s($index + 1))])]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cust_name",
                      align: "center",
                      label: "客户名称",
                      "min-width": "auto",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cust_pinyin",
                      align: "center",
                      label: "助记码",
                      "min-width": "auto",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "boss",
                      align: "center",
                      label: "老板名称",
                      "min-width": "120px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "mobile",
                      align: "center",
                      label: "老板手机",
                      "min-width": "auto",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      align: "center",
                      label: "老板地址",
                      "min-width": "auto",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "area_name",
                      align: "center",
                      label: "片区",
                      "min-width": "auto",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "channel_name",
                      align: "center",
                      label: "渠道",
                      "min-width": "auto",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "margin-top": "5px", "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }